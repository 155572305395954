.check-container {
  width: 30%;
  margin-left: 20px;
  margin-top: 20px;

  .check-box {
    border: 1px solid #cbcfd7;
    border-radius: 0.375rem;
    padding: 10px 12px;
    background: #fff;

    p {
      color: #000;
      font-weight: 700;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    .cash-check {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 20px;

      .check-list {
        width: 60%;
        padding: 0;
        list-style: none;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        li {
          font-size: 12px;
          margin: 0;
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        }
      }

      .check-cost {
        width: 40%;
        padding: 0;
        list-style: none;
        margin: 0;
        text-align: end;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        li {
          font-size: 12px;
          font-weight: bold;
          margin: 0;
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}

.dark-check-container {
  width: 30%;
  margin-left: 20px;
  margin-top: 20px;

  .check-box {
    border: 1px solid #373e53;
    border-radius: 0.375rem;
    padding: 10px 12px;
    background: #141824;

    p {
      color: #9fa6bc;
      font-weight: 700;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    .cash-check {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;
      margin-bottom: 20px;

      .check-list {
        width: 60%;
        padding: 0;
        list-style: none;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        li {
          font-size: 12px;
          margin: 0;
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          color: #9fa6bc;
        }
      }

      .check-cost {
        width: 40%;
        padding: 0;
        list-style: none;
        margin: 0;
        text-align: end;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        li {
          font-size: 12px;
          font-weight: bold;
          margin: 0;
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #9fa6bc;
        }
      }
    }
  }
}
