.accordion-item-wrapper.with-children {
  border: 1px solid #ccc; /* Add border if it has children */
  border-radius: 5px;
  margin-bottom: 10px;
  background-color: #fff;

  &:hover {
    cursor: pointer;
    box-shadow:
      rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
}

.simple-checkbox {
  padding: 12px 0.5rem;
  margin-bottom: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #000;
  font-weight: 700;

  &:hover {
    box-shadow:
      rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
}

.accordion-item {
  border-bottom: none !important;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: #6c63ff; /* Customize the color */
  background-color: inherit;
  border: none;
  outline: none;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #000 !important; /* Customize the color for active state */
  background-color: inherit;
}

// .accordion-button::after {
//   display: none; /* Remove default accordion icons */
// }

.accordion-button .ms-auto {
  font-size: 1.5rem;
  color: #6c63ff; /* Customize this color as needed */
}

.accordion-body {
  padding-left: 20px;

  ul {
    padding: 0;
    margin: 0;
    li {
      margin-left: 20px;
      list-style-type: none;
      display: flex;
      gap: 10px;
      color: #000;
      font-weight: 700;
    }
  }
}
