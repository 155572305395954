.maskedInput {
  height: 37px;
  width: 100%;
  border-radius: 2px;

  .input {
    height: 100%;
    width: 100%;
    padding: 8px 16px 8px 35px;
    outline: none;
    background-color: transparent;
    border: 1px solid #cbd0dd !important;
    transition: all 0.25s ease-in-out;
    color: #31374a;
    border-radius: 8px;
    font-size: 12.8px;
    line-height: 1.49;
    font-weight: 600;
    background-color: #fff;
    &:disabled {
      height: 37px !important;
      background-color: #eef0f2;
      color: #9fa6bc;
    }
    &::placeholder {
      color: #9fa6bc;
    }

    &:focus-visible,
    &:active {
      border: 1px solid #8200bf !important;
      border-color: #8200bf;
      outline: 0;
      box-shadow:
        inset 0 1px 2px transparent,
        0 0 0 0.25rem rgba(56, 116, 255, 0.25);
      transition: all 0.25s ease-in-out;
    }
  }
  .invalid {
    border-color: #ed2000 !important;
    padding-right: calc(1.49em + 1rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ed2000'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ed2000' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.3725em + 0.25rem) center;
    background-size: calc(0.745em + 0.5rem) calc(0.745em + 0.5rem);

    &:focus-visible,
    &:active {
      border: 1px solid #ed2000 !important;
      border-color: #ed2000 !important;
      box-shadow:
        inset 0 1px 2px transparent,
        0 0 0 0.25rem rgba(237, 32, 0, 0.25) !important;
      transition: all 0.25s ease-in-out;
    }
  }
}

.maskedInputDark {
  height: 37px;
  width: 100%;
  border-radius: 2px;

  .input {
    height: 100%;
    width: 100%;
    padding: 8px 16px 8px 35px;
    outline: none;
    background-color: transparent;
    border: 1px solid #373e53 !important;
    transition: all 0.25s ease-in-out;
    color: #9fa6bc;
    border-radius: 8px;
    font-size: 12.8px;
    line-height: 1.49;
    font-weight: 600;
    background-color: #141824;
    &:disabled {
      height: 37px !important;
      background-color: #eef0f2;
      color: #9fa6bc;
    }
    &::placeholder {
      color: #9fa6bc;
    }

    &:focus-visible,
    &:active {
      border: 1px solid #8200bf !important;
      border-color: #8200bf;
      outline: 0;
      box-shadow:
        inset 0 1px 2px transparent,
        0 0 0 0.25rem rgba(56, 116, 255, 0.25);
      transition: all 0.25s ease-in-out;
    }
  }
  .invalid {
    border-color: #ed2000 !important;
    padding-right: calc(1.49em + 1rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ed2000'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ed2000' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.3725em + 0.25rem) center;
    background-size: calc(0.745em + 0.5rem) calc(0.745em + 0.5rem);

    &:focus-visible,
    &:active {
      border: 1px solid #ed2000 !important;
      border-color: #ed2000 !important;
      box-shadow:
        inset 0 1px 2px transparent,
        0 0 0 0.25rem rgba(237, 32, 0, 0.25) !important;
      transition: all 0.25s ease-in-out;
    }
  }
}

.errMsg {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 75%;
  color: #ed2000 !important;
}

.phoneInput {
  padding: 2px 16px;
  border: 1px solid #cbd0dd;
  height: 48px;
  width: 100%;
  border-radius: 6px;
  outline: none;
  font-size: 12.8px;
  line-height: 1.49;
  font-weight: 600;

  &:focus-within {
    border: 1px solid #8200bf !important;
    border-color: #8200bf;
    outline: 0;
    box-shadow:
      inset 0 1px 2px transparent,
      0 0 0 0.25rem rgba(56, 116, 255, 0.25);
    transition: all 0.25s ease-in-out;
  }

  input {
    border: none;
    outline: none;

    &::placeholder {
      color: #9fa6bc;
    }
  }
}

.phoneInputDark {
  padding: 2px 16px;
  border: 1px solid #373e53;
  height: 48px;
  width: 100%;
  border-radius: 6px;
  outline: none;
  font-size: 12.8px;
  line-height: 1.49;
  font-weight: 600;

  &:focus-within {
    border: 1px solid #8200bf !important;
    border-color: #8200bf;
    outline: 0;
    box-shadow:
      inset 0 1px 2px transparent,
      0 0 0 0.25rem rgba(56, 116, 255, 0.25);
    transition: all 0.25s ease-in-out;
  }

  input {
    border: none;
    outline: none;
    background-color: #141824;
    color: #9fa6bc;

    &::placeholder {
      color: #9fa6bc;
    }
  }
}
.invalid {
  border-color: #ed2000 !important;
  padding-right: calc(1.49em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ed2000'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ed2000' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.3725em + 0.25rem) center;
  background-size: calc(0.745em + 0.5rem) calc(0.745em + 0.5rem);

  &:focus-visible,
  &:active {
    border: 1px solid #ed2000 !important;
    border-color: #ed2000 !important;
    box-shadow:
      inset 0 1px 2px transparent,
      0 0 0 0.25rem rgba(237, 32, 0, 0.25) !important;
    transition: all 0.25s ease-in-out;
  }
}

.CSelect {
  font-size: 12.8px;
  line-height: 1.49;
  font-weight: 600;
  letter-spacing: 0.41px;
  border: 1px solid #cbd0dd;
  color: #31374a;
  border-radius: 8px;
  fieldset {
    border: none;
    // font-size: 12.8px;
    // line-height: 1.49;
    // font-weight: 600;
    // letter-spacing: 0.41px;
    // border: 1px solid #cbd0dd;
    // color: #31374a;
    // border-radius: 8px;
    .label {
      color: red;
    }
    &:hover,
    &:focus-visible,
    &:active {
      border: 1px solid #8200bf;
      border-color: #8200bf;
      outline: 0;
      box-shadow:
        inset 0 1px 2px transparent,
        0 0 0 0.25rem rgba(56, 116, 255, 0.25);
      transition: all 0.25s ease-in-out;
    }
  }
}
