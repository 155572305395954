.Gallery {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 5px;

  .hidden {
    display: none;
  }

  .block {
    width: 140px;
    height: 90px;
    border-radius: 8px;
    border: 1px solid silver;
    cursor: pointer;
    // background-color: rgba(192, 192, 192, 0.267);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
    transition-duration: 0.4s;

    &:hover {
      // transform: translateY(-5px);
      border-color: #8200bf;
    }

    &:active {
      transform: translateY(0);
    }

    &:hover > .close-btn {
      opacity: 1;
      transform: translate(0, 0);
    }

    .close-btn {
      position: absolute;
      top: 5px;
      right: 5px;
      opacity: 0;
      transition-duration: 0.3s;
      color: rgba($color: #000000, $alpha: 0.4);
      transform: translate(15px, -15px);
      outline: none;
      border: none;
      background-color: transparent;
      cursor: pointer;

      &:hover {
        color: #8200bf;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .add-block {
    .add-icon {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
