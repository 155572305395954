.categoryBox {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px;
  border: 1px solid #cbd0dd;
  border-radius: 0.375rem;
  .title {
    font-size: 14px;
    font-weight: 600;
  }
}
