.container {
  position: relative;
  height: 30vh;
  .box {
    position: absolute;
    top: 20%;
    left: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100px;
      height: 100px;
    }
    .text {
      font-weight: 600;
      font-size: 16px;
    }
  }
}

@media (max-width: 768px) {
  .container {
    position: relative;
    height: 30vh;
    .box {
      position: absolute;
      top: 20%;
      left: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 80px;
        height: 80px;
      }
      .text {
        text-align: center;
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
}

.paper {
  margin-top: 10px;
  margin-right: 10px;
  filter: drop-shadow(0px 0px 1px rgba(26, 32, 36, 0.035))
    drop-shadow(0px 12px 24px rgba(91, 104, 113, 0.24));
}

.menu {
  width: 200px;
  .menu-item {
    display: flex;
    flex-direction: column;
    padding: 10px 12px;

    gap: 8px;
    background-color: white;
    cursor: pointer;

    &.active {
      color: #fff;
      background-color: #8200bf;

      &:hover {
        background: #8200bf;
      }
    }

    .dragIcon {
      cursor: grab;
    }

    .itemText {
      margin-bottom: 0;
      flex: 1;
      font-size: 14px;
    }

    // &:hover {
    //   background: #f6f8f9;
    // }
  }
}

.rowModel {
  &:hover {
    background-color: white !important;
  }
}

.icons {
  width: 18px;
  height: 20px;
  color: #8200bf;

  &:hover {
    cursor: pointer;
    border-radius: 100%;
    border: 1px solid #8200bf;
  }
}

.settings-container {
  .box {
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 0.375rem;

    &:hover {
      cursor: pointer;
      box-shadow:
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }

    .form-check {
      display: flex;
      gap: 10px;
      align-items: center;
      .form-check-label {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 16px;
      }
    }
  }
}
