.print-check-container {
  flex: 1;
  width: 80mm;
  margin: 0;
  padding: 0 20px 0 0;

  .legal-entity-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 3px dashed #000;
    padding-bottom: 10px;

    .dublicat {
      padding: 0;
      margin: 0;
      color: #000;
      font-weight: 700;
      letter-spacing: 1px;
      font-size: 12px;
      text-transform: uppercase;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }
    .title {
      padding: 0;
      margin: 0;
      color: #000;
      font-weight: 700;
      font-size: 12px;
      text-transform: uppercase;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    .address {
      padding: 0;
      margin: 0;
      color: #000;
      font-weight: 600;
      font-size: 12px;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    .phone {
      padding: 0;
      margin: 0;
      color: #000;
      font-weight: 600;
      font-size: 12px;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }
  }

  .receipt-info {
    display: flex;
    justify-content: space-between;
    padding: 4px;
    border-bottom: 3px dashed #000;

    .receipt-titles-header {
      padding: 0;
      list-style: none;
      margin-bottom: 0;
      li {
        color: #000;
        font-weight: 700;
        font-size: 12px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      }
    }

    .receipt-titles {
      padding: 0;
      list-style: none;
      margin-bottom: 0;

      li {
        text-align: end;
        color: #000;
        font-size: 12px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 500;
      }
    }
  }

  .sales-info {
    padding: 4px;
    border-bottom: 3px dashed #000;

    .sales-header {
      display: flex;
      gap: 23px;
      .header-print {
        padding: 0;
        margin: 0;
        color: #000;
        font-weight: 700;
        font-size: 10px;
        text-transform: uppercase;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      }
      .header-print:nth-child(3) {
        padding: 0;
        margin: 0 0 0 60px;
        color: #000;
        font-weight: 700;
        font-size: 10px;
        text-transform: uppercase;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      }
      .header-print:nth-child(4) {
        padding: 0;
        margin: 0 0 0 10px;
        color: #000;
        font-weight: 700;
        font-size: 10px;
        text-transform: uppercase;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      }
      .header-print-uz {
        padding: 0;
        margin: 0 0 0 20px;
        color: #000;
        font-weight: 700;
        font-size: 10px;
        text-transform: uppercase;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      }
      .header-print-uz-pay {
        padding: 0;
        margin: 0;
        color: #000;
        font-weight: 700;
        font-size: 10px;
        text-transform: uppercase;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      }
    }

    .sales-box {
      display: flex;
      justify-content: space-between;

      .sales-names {
        width: 55%;
        display: flex;

        .order {
          padding: 0;
          margin: 0;
          color: #000;
          font-size: 10px;
          width: 18px;
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          font-weight: 500;
        }

        .titles {
          padding: 0;
          margin: 0;
          color: #000;
          font-size: 10px;
          text-align: start;
          font-weight: 700;
          width: 80%;
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        }
      }

      .sales-costs-box {
        width: 45%;

        .sales-costs {
          display: flex;
          justify-content: space-between;
          text-align: end;

          .calculation {
            padding: 0;
            margin: 0;
            color: #000;
            font-size: 10px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 500;
          }

          .total {
            padding: 0;
            margin: 0;
            color: #000;
            font-size: 10px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 500;
          }
        }

        .sales-costs-returned {
          display: flex;
          justify-content: space-between;
          text-align: end;

          .calculation {
            padding: 0;
            margin: 0;
            color: #000;
            font-size: 12px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 500;
            text-decoration: line-through;
          }

          .total {
            padding: 0;
            margin: 0;
            color: #000;
            font-size: 12px;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 500;
            text-decoration: line-through;
          }
        }

        .sales-costs-vat {
          text-align: end;
          .vat {
            padding: 0;
            margin: 0;
            font-size: 10px;
            color: #000;
            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
            font-weight: 500;
          }
        }
      }
    }
  }

  .sales-total {
    display: flex;
    justify-content: space-between;
    padding: 4px;
    border-bottom: 3px dashed #000;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        color: #000;
        font-size: 12px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 500;
      }
    }
    ul:nth-child(2) {
      li {
        text-align: end;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 500;
      }
    }
  }

  .to-pay {
    display: flex;
    justify-content: space-between;
    padding: 4px;

    .pay-title {
      padding: 0;
      margin: 0;
      color: #000;
      font-size: 18px;
      font-weight: 700;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    .pay-sum {
      padding: 0;
      margin: 0;
      color: #000;
      font-size: 18px;
      font-weight: 700;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }
  }

  .is-paied {
    display: flex;
    justify-content: space-between;
    padding: 4px;

    .is-paied-title {
      padding: 0;
      margin: 0;
      color: #000;
      font-size: 12px;
      font-weight: 700;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }

    .is-paied-sum {
      padding: 0;
      margin: 0;
      color: #000;
      font-size: 12px;
      font-weight: 700;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }
  }

  .sale-transaction {
    display: flex;
    justify-content: space-between;
    padding: 0 4px;

    .title {
      padding: 0;
      margin: 0;
      color: #000;
      font-size: 12px;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 500;
    }

    .sum {
      padding: 0;
      margin: 0;
      color: #000;
      font-size: 12px;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 500;
    }
  }

  .extra {
    display: flex;
    justify-content: space-between;
    padding: 4px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        color: #000;
        font-size: 12px;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 500;
      }
    }
    ul:nth-child(2) {
      li {
        text-align: end;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-weight: 500;
      }
    }
  }

  .is-debt {
    display: flex;
    justify-content: space-between;
    padding: 4px;
    margin-bottom: 10px;

    .is-debt-title {
      padding: 0;
      margin: 0;
      color: #000;
      font-size: 12px;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 500;
    }

    .is-debt-sum {
      padding: 0;
      margin: 0;
      color: #000;
      font-size: 12px;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      font-weight: 500;
    }
  }

  .check-print {
    border-top: 3px dashed #000;
    padding: 16px 0;
  }
}
