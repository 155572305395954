.report-main-container {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  width: 75%;

  .card-60-40 {
    width: 60mm;
    height: 40mm;
    border: 1px solid #ccc;
    padding: 8px;
    position: relative;

    &:hover {
      cursor: pointer;
      box-shadow:
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }

    &.active {
      border: 2px solid #8200bf;
      box-shadow:
        rgba(50, 50, 93, 0.5) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.5) 0px -2px 6px 0px inset;
    }

    .size-title {
      bottom: 0;
      position: absolute;
      margin: 0;
      font-size: 11px;
      color: #8200bf;
      font-weight: bold;
    }

    .card-body {
      display: flex;
      gap: 10px;

      .logo {
        width: 30%;
        margin: auto 0;
        img {
          width: 100%;
          height: 60px;
        }
      }

      .body-items {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .filial {
          margin: 0;
          font-size: 8px;
          font-weight: 600;
          letter-spacing: 0.5px;
        }

        .title {
          margin: 0;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 1px;
        }

        .cost-box {
          display: flex;
          align-items: center;

          .cost {
            font-size: 26px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .cost-changed {
            margin-top: 15px;
            font-size: 30px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .cost-divider {
            display: flex;
            flex-direction: column;
            align-items: center;
            .top {
              font-size: 12px;
              border-bottom: 1px solid black;
              font-weight: bold;
              letter-spacing: 1px;
            }
            .bottom {
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 1px;
            }
          }

          .cost-divider-changed {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 15px;
            .top {
              font-size: 13px;
              border-bottom: 1px solid black;
              font-weight: bold;
              letter-spacing: 1px;
            }
            .bottom {
              font-size: 13px;
              font-weight: bold;
              letter-spacing: 1px;
            }
          }
        }

        .time {
          margin-left: auto;
          font-size: 8px;
          font-weight: bold;
        }
      }
      .scale-code {
        font-size: 11px;
        color: #000;
        font-weight: 700;
        position: absolute;
        bottom: 11%;
        left: 35%;
      }
    }
  }

  .card-58-30 {
    width: 58mm;
    height: 30mm;
    border: 1px solid #ccc;
    padding: 4px;
    position: relative;

    &:hover {
      cursor: pointer;
      box-shadow:
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }

    &.active {
      border: 2px solid #8200bf;
      box-shadow:
        rgba(50, 50, 93, 0.5) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.5) 0px -2px 6px 0px inset;
    }

    .size-title {
      bottom: 0;
      position: absolute;
      margin: 0;
      font-size: 10px;
      color: #8200bf;
      font-weight: bold;
    }

    .card-body {
      width: 100%;
      .body-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .filial {
          margin: 0;
          font-size: 5px;
          font-weight: 500;
          letter-spacing: 0.5px;
        }

        .title {
          margin: 0;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
        }

        .cost-box {
          display: flex;
          align-items: center;

          .cost {
            font-size: 20px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .cost-changed {
            margin-top: 10px;
            font-size: 32px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .cost-divider {
            display: flex;
            flex-direction: column;
            align-items: center;
            .top {
              font-size: 10px;
              border-bottom: 1px solid black;
              font-weight: bold;
              letter-spacing: 1px;
            }
            .bottom {
              font-size: 10px;
              font-weight: bold;
              letter-spacing: 1px;
            }
          }

          .cost-divider-changed {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .top {
              font-size: 13px;
              border-bottom: 1px solid black;
              font-weight: bold;
              letter-spacing: 1px;
            }
            .bottom {
              font-size: 13px;
              font-weight: bold;
              letter-spacing: 1px;
            }
          }
        }

        .time {
          margin-top: -12px;
          margin-left: auto;
          font-size: 8px;
          font-weight: bold;
        }
      }
      .scale-code {
        font-size: 11px;
        color: #000;
        font-weight: 700;
        position: absolute;
        bottom: 3%;
        left: 25%;
      }
    }
  }

  .card-40-30 {
    width: 40mm;
    height: 30mm;
    border: 1px solid #ccc;
    padding: 4px;
    position: relative;

    &:hover {
      cursor: pointer;
      box-shadow:
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }

    &.active {
      border: 2px solid #8200bf;
      box-shadow:
        rgba(50, 50, 93, 0.5) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.5) 0px -2px 6px 0px inset;
    }
    .size-title {
      bottom: 0;
      position: absolute;
      margin: 0;
      font-size: 8px;
      color: #8200bf;
      font-weight: bold;
    }

    .card-body {
      width: 100%;
      .body-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .filial {
          margin: 0;
          font-size: 5px;
          font-weight: 500;
          letter-spacing: 0.5px;
        }

        .title {
          margin: 0;
          font-size: 10px;
          font-weight: 500;
          letter-spacing: 1px;
        }

        .cost-box {
          display: flex;
          align-items: center;

          .cost {
            font-size: 20px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .cost-changed {
            margin-top: 12px;
            font-size: 24px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .cost-divider {
            display: flex;
            flex-direction: column;
            align-items: center;
            .top {
              font-size: 10px;
              border-bottom: 1px solid black;
              font-weight: bold;
              letter-spacing: 1px;
            }
            .bottom {
              font-size: 10px;
              font-weight: bold;
              letter-spacing: 1px;
            }
          }
          .cost-divider-changed {
            margin-top: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .top {
              font-size: 12px;
              border-bottom: 1px solid black;
              font-weight: bold;
              letter-spacing: 1px;
            }
            .bottom {
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 1px;
            }
          }
        }

        .time {
          margin-top: -8px;
          margin-left: auto;
          font-size: 8px;
          font-weight: bold;
        }
      }
    }
  }

  .card-30-20 {
    width: 30mm;
    height: 20mm;
    padding: 4px;
    position: relative;
    border: 1px solid #ccc;

    &:hover {
      cursor: pointer;
      box-shadow:
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }

    &.active {
      border: 2px solid #8200bf;
      box-shadow:
        rgba(50, 50, 93, 0.5) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.5) 0px -2px 6px 0px inset;
    }

    .size-title {
      bottom: 0;
      position: absolute;
      margin: 0;
      font-size: 6px;
      color: #8200bf;
      font-weight: bold;
    }

    .card-body {
      width: 100%;
      .body-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          margin: 0;
          font-size: 7px;
          font-weight: 500;
          letter-spacing: 1px;
        }

        .cost-box {
          display: flex;
          align-items: center;

          .cost {
            font-size: 17px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .cost-changed {
            margin-top: 12px;
            font-size: 20px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .cost-divider {
            display: flex;
            flex-direction: column;
            align-items: center;
            .top {
              font-size: 6px;
              border-bottom: 1px solid black;
              font-weight: bold;
              letter-spacing: 1px;
            }
            .bottom {
              font-size: 6px;
              font-weight: bold;
              letter-spacing: 1px;
            }
          }
          .cost-divider-changed {
            margin-top: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .top {
              font-size: 8px;
              border-bottom: 1px solid black;
              font-weight: bold;
              letter-spacing: 1px;
            }
            .bottom {
              font-size: 8px;
              font-weight: bold;
              letter-spacing: 1px;
            }
          }
        }

        .time {
          margin-top: -12px;
          margin-left: auto;
          font-size: 4px;
          font-weight: bold;
        }
      }
    }
  }

  .card-line-58-30 {
    width: 58mm;
    height: 30mm;
    border: 1px solid #ccc;
    padding: 4px;
    position: relative;

    &:hover {
      cursor: pointer;
      box-shadow:
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }

    &.active {
      border: 2px solid #8200bf;
      box-shadow:
        rgba(50, 50, 93, 0.5) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.5) 0px -2px 6px 0px inset;
    }

    .size-title {
      bottom: 0;
      position: absolute;
      margin: 0;
      font-size: 10px;
      color: #8200bf;
      font-weight: bold;
    }

    .card-body {
      width: 100%;
      .body-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          margin: 0;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 1px;
        }

        .cost-box {
          display: flex;
          align-items: center;
          gap: 5px;

          .cost {
            margin-top: 2px;
            font-size: 24px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .cost-changed {
            margin-top: 15px;
            font-size: 32px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .bottom {
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 1px;
            margin-top: 2px;
          }
          .bottom-changed {
            margin-top: 16px;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 1px;
          }
        }

        .time {
          margin-top: -12px;
          margin-left: auto;
          font-size: 8px;
          font-weight: bold;
        }
      }
    }
  }

  .card-line-40-30 {
    width: 40mm;
    height: 30mm;
    border: 1px solid #ccc;
    padding: 4px;
    position: relative;

    &:hover {
      cursor: pointer;
      box-shadow:
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }

    &.active {
      border: 2px solid #8200bf;
      box-shadow:
        rgba(50, 50, 93, 0.5) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.5) 0px -2px 6px 0px inset;
    }

    .size-title {
      bottom: 0;
      position: absolute;
      margin: 0;
      font-size: 8px;
      color: #8200bf;
      font-weight: bold;
    }

    .card-body {
      width: 100%;
      .body-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          margin: 0;
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 1px;
        }

        .cost-box {
          display: flex;
          align-items: center;
          gap: 5px;

          .cost {
            font-size: 22px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }

          .cost-changed {
            margin-top: 12px;
            font-size: 24px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .bottom {
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 1px;
          }

          .bottom-changed {
            margin-top: 12px;
            font-size: 20px;
            font-weight: bold;
            letter-spacing: 1px;
          }
        }

        .time {
          margin-top: -5px;
          margin-left: auto;
          font-size: 8px;
          font-weight: bold;
        }
      }
    }
  }
  .card-line-def-58-30 {
    width: 58mm;
    height: 30mm;
    border: 1px solid #ccc;
    padding: 4px;
    position: relative;

    &:hover {
      cursor: pointer;
      box-shadow:
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }

    &.active {
      border: 2px solid #8200bf;
      box-shadow:
        rgba(50, 50, 93, 0.5) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.5) 0px -2px 6px 0px inset;
    }

    .size-title {
      bottom: 0;
      position: absolute;
      margin: 0;
      font-size: 10px;
      color: #8200bf;
      font-weight: bold;
    }

    .card-body {
      width: 100%;
      .body-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          margin: 8px 0 0 0;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 1px;
        }

        .cost-box {
          display: flex;
          align-items: center;
          gap: 5px;

          .cost {
            font-size: 30px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .cost-changed {
            margin-top: 15px;
            font-size: 32px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .bottom {
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 1px;
            margin-top: 2px;
          }
          .bottom-changed {
            margin-top: 16px;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  .card-70-40 {
    width: 70mm;
    height: 40mm;
    border: 1px solid #ccc;
    padding: 8px;
    position: relative;

    &:hover {
      cursor: pointer;
      box-shadow:
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }

    &.active {
      border: 2px solid #8200bf;
      box-shadow:
        rgba(50, 50, 93, 0.5) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.5) 0px -2px 6px 0px inset;
    }

    .size-title {
      bottom: 0;
      position: absolute;
      margin: 0;
      font-size: 11px;
      color: #8200bf;
      font-weight: bold;
    }

    .card-body {
      display: flex;
      gap: 2px;

      .logo {
        width: 30%;
        margin: auto 0;
        img {
          width: 100%;
          height: 75px;
        }
      }

      .body-items {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .filial {
          margin: 0;
          font-size: 10px;
          font-weight: 600;
          letter-spacing: 0.5px;
        }

        .title {
          margin: 0;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 1px;
        }

        .cost-box {
          display: flex;
          align-items: center;

          .cost {
            font-size: 28px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .cost-changed {
            margin-top: 15px;
            font-size: 32px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .cost-divider {
            display: flex;
            flex-direction: column;
            align-items: center;
            .top {
              font-size: 14px;
              border-bottom: 1px solid black;
              font-weight: bold;
              letter-spacing: 1px;
            }
            .bottom {
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 1px;
            }
          }

          .cost-divider-changed {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 15px;
            .top {
              font-size: 15px;
              border-bottom: 1px solid black;
              font-weight: bold;
              letter-spacing: 1px;
            }
            .bottom {
              font-size: 15px;
              font-weight: bold;
              letter-spacing: 1px;
            }
          }
        }

        .time {
          margin-left: auto;
          font-size: 8px;
          font-weight: bold;
        }
      }
      .scale-code {
        font-size: 11px;
        color: #000;
        font-weight: 700;
        position: absolute;
        bottom: 12%;
        left: 40%;
      }
    }
  }

  .card-line-def-70-40 {
    width: 70mm;
    height: 40mm;
    border: 1px solid #ccc;
    padding: 4px;
    position: relative;

    &:hover {
      cursor: pointer;
      box-shadow:
        rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    }

    &.active {
      border: 2px solid #8200bf;
      box-shadow:
        rgba(50, 50, 93, 0.5) 0px 50px 100px -20px,
        rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
        rgba(10, 37, 64, 0.5) 0px -2px 6px 0px inset;
    }

    .size-title {
      bottom: 0;
      position: absolute;
      margin: 0;
      font-size: 10px;
      color: #8200bf;
      font-weight: bold;
    }

    .card-body {
      width: 100%;
      .body-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          margin: 22px 0 0 0;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 1px;
        }

        .cost-box {
          display: flex;
          align-items: center;
          gap: 5px;

          .cost {
            font-size: 32px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .cost-changed {
            margin-top: 15px;
            font-size: 34px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .bottom {
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 1px;
            margin-top: 2px;
          }
          .bottom-changed {
            margin-top: 16px;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}
