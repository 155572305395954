.list {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 6px;
  background: #fff;
  padding: 4px;
  border: 1px solid #e3e6ed;

  button {
    outline: none;
    border: none;
    background: none;
    padding: 8px 39px;
    font-size: 14px;
    line-height: 24px;
    margin: 0 2px;

    &:hover {
      padding: 8px 39px;
      background: #f5f5f5;
      transition: 0.3 ease;
      border-radius: 6px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      border: 1px solid #e3e6ed;
      margin: 0 2px;
    }
  }

  .active {
    padding: 8px 39px;
    background: #f5f5f5;
    transition: 0.3 ease;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    border: 1px solid #e3e6ed;
    margin: 0 2px;
  }
}

.listDark {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 6px;
  background: #141824;
  padding: 4px;
  border: 1px solid #373e53;

  button {
    outline: none;
    border: none;
    background: none;
    padding: 8px 39px;
    font-size: 14px;
    line-height: 24px;
    margin: 0 2px;
    color: #9fa6bc;

    &:hover {
      padding: 8px 39px;
      background: #141824;
      transition: 0.3 ease;
      border-radius: 6px;
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      border: 1px solid #373e53;
      margin: 0 2px;
      color: #8a2be2;
    }
  }

  .active {
    padding: 8px 39px;
    background: #141824;
    transition: 0.3 ease;
    border-radius: 6px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    border: 1px solid #373e53;
    margin: 0 2px;
    color: #8a2be2;
  }
}

.infoConteiner {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 24px;

  .box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #e3e6ed;
    padding: 24px;
    background: #fff;
    .boxInfo {
      display: flex;
      flex-direction: column;
      .boxTitle {
        margin-bottom: 4px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.4px;
        color: #84919a;
      }
      .boxCount {
        font-weight: 600;
        font-size: 24px;
        line-height: 29.1px;
        color: #000000;
      }
    }
  }
}

.infoConteinerDark {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 24px;

  .box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-radius: 6px;
    border: 1px solid #373e53;
    padding: 24px;
    background: #141824;
    .boxInfo {
      display: flex;
      flex-direction: column;
      .boxTitle {
        margin-bottom: 4px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19.4px;
        color: #84919a;
      }
      .boxCount {
        font-weight: 600;
        font-size: 24px;
        line-height: 29.1px;
        color: #9fa6bc;
      }
    }
  }
}

.PTInfo {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 24px;
  width: 100%;

  .PTBox {
    width: 50%;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid #e3e6ed;
    padding: 24px;
    background: #fff;

    .topInfo {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
      border-bottom: 1px solid #e3e6ed;
      margin-bottom: 12px;

      .paymenInfo {
        .topTitle {
          margin-bottom: 4px;
          font-weight: 400;
          font-size: 16px;
          line-height: 19.4px;
          color: #84919a;
        }
        .topCount {
          font-weight: 600;
          font-size: 24px;
          line-height: 29.1px;
          color: #000000;
        }
      }
    }

    .bottomInfo {
      display: flex;
      justify-content: space-between;

      .bTitles {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          text-align: start;
        }
      }

      .bCounts {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          text-align: end;
        }
      }
    }
  }
}

.PTInfoDark {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 24px;
  width: 100%;

  .PTBox {
    width: 50%;
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    border: 1px solid #373e53;
    padding: 24px;
    background: #141824;

    .topInfo {
      display: flex;
      justify-content: space-between;
      padding-bottom: 12px;
      border-bottom: 1px solid #373e53;
      margin-bottom: 12px;

      .paymenInfo {
        .topTitle {
          margin-bottom: 4px;
          font-weight: 400;
          font-size: 16px;
          line-height: 19.4px;
          color: #84919a;
        }
        .topCount {
          font-weight: 600;
          font-size: 24px;
          line-height: 29.1px;
          color: #9fa6bc;
        }
      }
    }

    .bottomInfo {
      display: flex;
      justify-content: space-between;

      .bTitles {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #9fa6bc;
          text-align: start;
        }
      }

      .bCounts {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #9fa6bc;
          text-align: end;
        }
      }
    }
  }
}

.SGContainer {
  display: flex;
  gap: 20px;
  margin-top: 24px;
  justify-content: space-between;

  .SGBox {
    display: flex;
    flex-direction: column;
    width: 50%;
    border-radius: 6px;
    border: 1px solid #e3e6ed;
    padding: 24px;
    background: #fff;

    .title {
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.41px;
      color: #000000;
    }

    .SGSelects {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      // padding-bottom: 12px;
      border-bottom: 1px solid #e3e6ed;
    }

    .SGBInfo {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;

      .bTitles {
        width: 65%;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          text-align: start;
          text-overflow: ellipsis;
          overflow: hidden;
          // width: 160px;
          white-space: nowrap;
        }
      }

      .bCounts {
        width: 35%;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          text-align: end;
        }
      }
    }
  }
}

.SGContainerDark {
  display: flex;
  gap: 20px;
  margin-top: 24px;
  justify-content: space-between;

  .SGBox {
    display: flex;
    flex-direction: column;
    width: 50%;
    border-radius: 6px;
    border: 1px solid #373e53;
    padding: 24px;
    background: #141824;

    .title {
      margin-bottom: 12px;
      font-weight: 600;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.41px;
      color: #9fa6bc;
    }

    .SGSelects {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      // padding-bottom: 12px;
      border-bottom: 1px solid #373e53;
    }

    .SGBInfo {
      margin-top: 12px;
      display: flex;
      justify-content: space-between;

      .bTitles {
        width: 65%;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        li {
          cursor: pointer;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #9fa6bc;
          text-align: start;
          text-overflow: ellipsis;
          overflow: hidden;
          // width: 160px;
          white-space: nowrap;
        }
      }

      .bCounts {
        width: 35%;
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #9fa6bc;
          text-align: end;
        }
      }
    }
  }
}

.graphContainer {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #e3e6ed;
  padding: 24px;
  background: #fff;

  .saleInfo {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding-right: 24px;
    border-right: 1px solid #e3e6ed;

    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 19.4px;
      color: #84919a;
      margin-bottom: 4px;
    }
    .count {
      font-weight: 600;
      font-size: 24px;
      line-height: 29.1px;
      color: #000000;
    }

    .graphInfo {
      margin-top: 24px;
      display: flex;
      gap: 8px;
      align-items: center;
      border-radius: 6px;
      background: #f5f5f5;
      padding: 8px 12px;

      .dot {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background-color: #9103e4;
      }

      .selectedGraphInfo {
        display: flex;
        flex-direction: column;

        .sgTitle {
          margin-bottom: 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
        }
        .sgCount {
          font-weight: 600;
          font-size: 16px;
          line-height: 19.4px;
          color: #000000;
        }
      }
    }
  }

  .fullGraph {
    width: 20%;
    display: flex;
    flex-direction: column;
    padding-left: 24px;

    .switchToGraph {
      display: flex;
      background: #f5f5f5;
      border-radius: 6px;
      padding: 8px 12px;
      gap: 8px;
      margin-bottom: 12px;
      border: 1px solid #e3e6ed;

      .elips {
        width: 12px;
        height: 12px;
        background: #9103e4;
        border-radius: 100%;
        margin: auto 0;
      }

      .branchContent {
        display: flex;
        flex-direction: column;

        .branchTitle {
          font-weight: 400;
          font-size: 14px;
          line-height: 16.94px;
          color: #5b6871;
          margin-bottom: 4px;
          padding: 0;
        }
        .count {
          font-weight: 600;
          font-size: 14px;
          line-height: 16.94px;
          color: #000000;
        }
      }
    }
  }
}

.graphContainerDark {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #373e53;
  padding: 24px;
  background: #141824;

  .saleInfo {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding-right: 24px;
    border-right: 1px solid #373e53;

    .title {
      font-weight: 400;
      font-size: 16px;
      line-height: 19.4px;
      color: #84919a;
      margin-bottom: 4px;
    }
    .count {
      font-weight: 600;
      font-size: 24px;
      line-height: 29.1px;
      color: #9fa6bc;
    }

    .graphInfo {
      margin-top: 24px;
      display: flex;
      gap: 8px;
      align-items: center;
      border-radius: 6px;
      background: #141824;
      padding: 8px 12px;

      .dot {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        background-color: #9103e4;
      }

      .selectedGraphInfo {
        display: flex;
        flex-direction: column;

        .sgTitle {
          margin-bottom: 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
        }
        .sgCount {
          font-weight: 600;
          font-size: 16px;
          line-height: 19.4px;
          color: #9fa6bc;
        }
      }
    }
  }

  .fullGraph {
    width: 20%;
    display: flex;
    flex-direction: column;
    padding-left: 24px;

    .switchToGraph {
      display: flex;
      background: #141824;
      border-radius: 6px;
      padding: 8px 12px;
      gap: 8px;
      margin-bottom: 12px;
      border: 1px solid #373e53;

      .elips {
        width: 12px;
        height: 12px;
        background: #9103e4;
        border-radius: 100%;
        margin: auto 0;
      }

      .branchContent {
        display: flex;
        flex-direction: column;

        .branchTitle {
          font-weight: 400;
          font-size: 14px;
          line-height: 16.94px;
          color: #5b6871;
          margin-bottom: 4px;
          padding: 0;
        }
        .count {
          font-weight: 600;
          font-size: 14px;
          line-height: 16.94px;
          color: #9fa6bc;
        }
      }
    }
  }
}

.totalInYearContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 6px;
  border: 1px solid #e3e6ed;
  padding: 24px;
  margin-top: 24px;

  &:hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;

    .content .boxText .arrowRightIcon {
      transform: translateX(10px); /* Adjust the value to your preference */
      transition: transform 0.3s ease;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    .boxTitle {
      font-size: 24px;
      line-height: 29.05px;
      font-weight: 600;
      margin-bottom: 4px;
      padding: 0;
      color: #000000;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

      .arrowRightIcon {
        display: inline-block;
        transition: transform 0.3s ease;
      }
    }
    .boxText {
      font-weight: 400;
      font-size: 16px;
      line-height: 19.36px;
      color: #84919a;
      margin: 0;
      padding: 0;
      font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    }
  }
}
