.abcRows {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .abcBtnGroup {
    display: flex;
    gap: 10px;
  }
}

.tableContainer {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .abcRows {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .abcBtnGroup {
      display: flex;
      gap: 10px;
      margin-top: auto;
    }
  }

  .tableContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
