.profitRows {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .profitBtnGroup {
    display: flex;
    gap: 10px;
  }

  .categoryBox {
    height: 48px;
    position: relative;
    cursor: pointer;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    border: 1px solid #cbd0dd;
    border-radius: 0.375rem;
    background-color: #fff;
    .title {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .categoryBoxDark {
    height: 48px;
    position: relative;
    cursor: pointer;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    border: 1px solid #373e53;
    border-radius: 0.375rem;
    background-color: #141824;
    .title {
      font-size: 14px;
      font-weight: 600;
      color: #9fa6bc;
    }
  }
}

@media (max-width: 768px) {
  .profitRows {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .profitBtnGroup {
      display: flex;
      gap: 10px;
      margin-top: auto;
    }
  }
}
