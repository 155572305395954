.footer-info {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  gap: 20px;
  padding: 8px;
  .box {
    background-color: #d1d1d2;
    padding: 8px;
  }

  .main-check {
    display: flex;
    align-items: center;
  }
}

.new-rows {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  .new-btn {
    display: flex;
    gap: 10px;
    margin-top: auto;
  }
}

.main-btn-group {
  display: flex;
  gap: 10px;
}

.modal-rows {
  display: flex;
  gap: 20px;
  .frist-item {
    flex: 1;
  }
  .modal-btn-group {
    margin-top: auto;
    display: flex;
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .footer-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 8px;
    .box {
      width: 100%;
      align-items: center;
      background-color: #d1d1d2;
      padding: 8px;
    }
    .main-check {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  .new-rows {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .main-btn-group {
    // margin-top: 10px;
    width: 100%;
    display: flex;
    gap: 10px;
  }

  .modal-rows {
    display: flex;
    gap: 20px;

    .modal-btn-group {
      margin-top: auto;
      display: flex;
      gap: 10px;
    }
  }
}
