@media (max-width: 768px) {
  .header-title {
    margin-bottom: 10px;
  }

  .create-row {
    display: flex;
    flex-direction: column;
  }
}
