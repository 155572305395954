.create-rate {
  display: flex;
  flex-direction: column;
  .rate-box {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .create-rate {
    display: flex;
    flex-direction: column;
    .rate-box {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
    }
  }
}
