.purchaser-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 8px;
  .box {
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    background-color: #fff;
    padding: 10px;
  }
}
