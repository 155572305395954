.dark-footer-purchase {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 10px;
  .box {
    border: 1px solid #373e53;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: #141824;
    padding: 10px;
    color: #9fa6bc;
  }

  .main-btn-group {
    display: flex;
    gap: 10px;
  }
}

.footer-purchase {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 10px;
  .box {
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: #fff;
    padding: 10px;
  }

  .main-btn-group {
    display: flex;
    gap: 10px;
  }
}

.pur-new-rows {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .purbtngroup {
    display: flex;
    gap: 10px;
  }
}

.printing-rows {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgb(203, 208, 221);

  .printing-check {
    display: flex;
    align-items: center;
    margin: auto 0;
  }
  .nds {
    width: 120px;
  }
}

@media (max-width: 768px) {
  .footer-purchase {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 8px;
    .box {
      align-items: center;
      padding: 8px;
      background-color: #fff;
      border: 1px solid #f5f5f5;
      border-radius: 10px;
    }

    .main-btn-group {
      width: 100%;
      display: flex;
      gap: 10px;
    }
  }

  .pur-new-rows {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .purbtngroup {
      display: flex;
      gap: 10px;
    }
  }

  .printing-rows {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 1px solid #ccc;

    .nds {
      width: 100%;
    }
  }
}

.error {
  border-color: red !important;
}

.loading-table {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}

.loading-table::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  opacity: 0.3;
  z-index: 1;
  pointer-events: none;
}

.loading-table span {
  color: black;
  z-index: 2;
  pointer-events: none;
}
