.purchase-print-container {
  .card-60-40 {
    width: 60mm;
    height: 40mm;
    padding: 4px;

    .card-body {
      display: flex;
      gap: 2px;
      position: relative;

      .logo {
        width: 30%;
        margin: auto 0;
        img {
          width: 100%;
          height: 60px;
        }
      }

      .body-items {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .filial {
          margin: 5px 0 0 0;
          font-size: 8px;
          font-weight: 600;
          letter-spacing: 0.5px;
          color: #000;
        }

        .title {
          margin: 5px 0 0 0;
          font-size: 11px;
          font-weight: 600;
          color: #000;
          width: 100%;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .changed-title {
          margin: 5px 0 0 0;
          font-size: 11px;
          font-weight: 600;
          color: #000;
          width: 100%;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .cost-box {
          display: flex;
          align-items: center;

          .cost {
            margin-top: 4px;
            font-size: 22px;
            font-weight: 900;
            letter-spacing: 1px;
            color: #000;
          }
          .cost-changed {
            margin-top: 15px;
            font-size: 21px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
            color: #000;
          }
          .cost-divider {
            display: flex;
            flex-direction: column;
            align-items: center;
            .top {
              font-size: 11px;
              border-bottom: 1px solid black;
              font-weight: 600;
              letter-spacing: 1px;
              color: #000;
            }

            .bottom {
              font-size: 11px;
              font-weight: 600;
              letter-spacing: 1px;
              color: #000;
            }
          }
          .cost-divider-changed {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 15px;
            .top {
              font-size: 11px;
              border-bottom: 1px solid black;
              font-weight: bold;
              letter-spacing: 1px;
              color: #000;
            }
            .bottom {
              font-size: 11px;
              font-weight: bold;
              letter-spacing: 1px;
              color: #000;
            }
          }
        }

        .time {
          margin-left: auto;
          margin-right: 2px;
          font-size: 8px;
          color: #000;
          margin-right: 10px;
        }
      }

      .scale-code {
        font-size: 12px;
        color: #000;
        font-weight: 700;
        position: absolute;
        bottom: 10%;
        left: 35%;
      }
    }
  }

  .card-58-30 {
    width: 58mm;
    height: 30mm;
    padding: 4px;

    .card-body {
      width: 100%;
      position: relative;

      .body-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .filial {
          margin: 0;
          font-size: 6px;
          font-weight: 600;
          letter-spacing: 0.5px;
          color: #000;
        }

        .title {
          margin: 0;
          font-size: 10px;
          font-weight: 600;
          letter-spacing: 1px;
          color: #000;
          width: 100%;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .changed-title {
          margin: 6px 0 0 0;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 1px;
          color: #000;
          width: 100%;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .cost-box {
          display: flex;
          align-items: center;

          .cost {
            font-size: 24px;
            font-weight: 900;
            letter-spacing: 1px;
            color: #000;
            font-family: Arial, Helvetica, sans-serif;
          }

          .cost-changed {
            margin-top: 10px;
            font-size: 27px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
            color: #000;
          }
          .cost-divider {
            display: flex;
            flex-direction: column;
            align-items: center;
            .top {
              font-size: 10px;
              border-bottom: 1px solid black;
              font-weight: 600;
              letter-spacing: 1px;
              color: #000;
            }
            .bottom {
              font-size: 10px;
              font-weight: 600;
              letter-spacing: 1px;
              color: #000;
            }
          }

          .cost-divider-changed {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .top {
              font-size: 12px;
              border-bottom: 1px solid black;
              font-weight: bold;
              letter-spacing: 1px;
              color: #000;
            }
            .bottom {
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 1px;
              color: #000;
            }
          }
        }

        .time {
          margin-top: -14px;
          margin-left: auto;
          font-size: 8px;
          font-weight: 600;
          color: #000;
          margin-right: 10px;
        }
      }

      .scale-code {
        font-size: 12px;
        color: #000;
        font-weight: 700;
        position: absolute;
        bottom: 2%;
        left: 15%;
      }
    }
  }

  .card-40-30 {
    width: 40mm;
    height: 30mm;

    .card-body {
      width: 100%;
      .body-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .filial {
          margin: 2px 0 0 0;
          font-size: 6px;
          font-weight: 600;
          letter-spacing: 0.5px;
          color: #000;
        }

        .title {
          padding: 0 0 0 5px;
          font-size: 9px;
          font-weight: 600;
          letter-spacing: 0.7px;
          color: #000;
          width: 100%;
          text-align: center;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          overflow: hidden;
          -webkit-box-orient: vertical;
          box-orient: vertical;
          white-space: normal;
          word-break: break-word;
          display: -webkit-box;
        }

        .changed-title {
          margin: 5px 0 0 0;
          font-size: 9px;
          font-weight: 600;
          letter-spacing: 0.7px;
          color: #000;
          width: 100%;
          text-align: center;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          overflow: hidden;
          -webkit-box-orient: vertical;
          box-orient: vertical;
          white-space: normal;
          word-break: break-word;
          display: -webkit-box;
        }

        .cost-box {
          display: flex;
          align-items: center;

          .cost {
            margin-top: -15px;
            margin-bottom: 4px;
            font-size: 17px;
            font-weight: 900;
            letter-spacing: 1px;
            color: #000;
          }

          .cost-changed {
            margin-top: 10px;
            font-size: 18px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
            color: #000;
          }
          .cost-divider {
            margin-top: -15px;
            margin-bottom: 4px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .top {
              font-size: 10px;
              border-bottom: 1px solid black;
              font-weight: 600;
              letter-spacing: 1px;
              color: #000;
            }
            .bottom {
              font-size: 10px;
              font-weight: 600;
              letter-spacing: 1px;
              color: #000;
            }
          }

          .cost-divider-changed {
            margin-top: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .top {
              font-size: 12px;
              border-bottom: 1px solid black;
              font-weight: bold;
              letter-spacing: 1px;
              color: #000;
            }
            .bottom {
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 1px;
              color: #000;
            }
          }
        }

        .time {
          margin-top: -10px;
          margin-left: auto;
          font-size: 6px;
          font-weight: 600;
          color: #000;
          margin-right: 10px;
        }
      }
    }
  }

  .card-30-20 {
    width: 30mm;
    height: 20mm;
    border: 1px solid white;

    .card-body {
      width: 100%;
      .body-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          margin: 4px 0 0 0;
          padding: 0 2px;
          font-size: 6px;
          font-weight: 600;
          letter-spacing: 0.7px;
          color: #000;
          width: 100%;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .changed-title {
          margin: 10px 0 0 0;
          font-size: 6px;
          font-weight: 600;
          letter-spacing: 0.7px;
          color: #000;
          width: 100%;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .cost-box {
          display: flex;
          align-items: center;

          .cost {
            font-size: 12px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
            color: #000;
          }
          .cost-changed {
            margin-top: 15px;
            font-size: 12px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
            color: #000;
          }
          .cost-divider {
            display: flex;
            flex-direction: column;
            align-items: center;
            .top {
              font-size: 6px;
              border-bottom: 1px solid black;
              font-weight: 600;
              letter-spacing: 1px;
              color: #000;
            }
            .bottom {
              font-size: 6px;
              font-weight: 600;
              letter-spacing: 1px;
              color: #000;
            }
          }

          .cost-divider-changed {
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .top {
              font-size: 8px;
              border-bottom: 1px solid black;
              font-weight: bold;
              letter-spacing: 1px;
              color: #000;
            }
            .bottom {
              font-size: 8px;
              font-weight: bold;
              letter-spacing: 1px;
              color: #000;
            }
          }
        }

        .time {
          margin-top: -12px;
          margin-left: auto;
          font-size: 4px;
          font-weight: 600;
          color: #000;
          margin-right: 10px;
        }
      }
    }
  }

  .card-line-58-30 {
    width: 58mm;
    height: 30mm;

    .card-body {
      width: 100%;
      .body-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          margin: 0;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 1px;
          color: #000;
          width: 100%;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .changed-title {
          margin: 10px 0 0 0;
          font-size: 12px;
          font-weight: 600;
          letter-spacing: 1px;
          color: #000;
          width: 100%;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .cost-box {
          display: flex;
          align-items: center;
          gap: 5px;

          .cost {
            margin-top: 2px;
            font-size: 20px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
            color: #000;
          }
          .cost-changed {
            margin-top: 15px;
            font-size: 21px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
            color: #000;
          }
          .bottom {
            font-size: 20px;
            font-weight: 600;
            letter-spacing: 1px;
            margin-top: 2px;
            color: #000;
          }
          .bottom-changed {
            margin-top: 16px;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 1px;
            color: #000;
          }
        }

        .time {
          margin-top: -8px;
          margin-left: auto;
          font-size: 8px;
          font-weight: 600;
          color: #000;
          margin-right: 10px;
        }
      }
    }
  }

  .card-line-40-30 {
    width: 40mm;
    height: 30mm;
    padding: 4px;

    .card-body {
      width: 100%;
      .body-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          margin: 0 0 0 5px;
          font-size: 8px;
          font-weight: 600;
          letter-spacing: 0.3px;
          color: #000;
          width: 100%;
          text-align: center;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          overflow: hidden;
          -webkit-box-orient: vertical;
          box-orient: vertical;
          white-space: normal;
          word-break: break-word;
          display: -webkit-box;
        }

        .changed-title {
          margin: 5px 0 0 0;
          font-size: 9px;
          font-weight: 600;
          letter-spacing: 0.7px;
          color: #000;
          width: 100%;
          text-align: center;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          overflow: hidden;
          -webkit-box-orient: vertical;
          box-orient: vertical;
          white-space: normal;
          word-break: break-word;
          display: -webkit-box;
        }

        .cost-box {
          display: flex;
          align-items: center;
          gap: 5px;

          .cost {
            font-size: 13px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
            color: #000;
            margin-top: 10px;
          }
          .cost-changed {
            margin-top: 16px;
            font-size: 13px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
            color: #000;
          }
          .bottom {
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 1px;
            color: #000;
            margin-top: 10px;
          }
          .bottom-changed {
            margin-top: 16px;
            font-size: 13px;
            font-weight: bold;
            letter-spacing: 1px;
            color: #000;
          }
        }

        .time {
          margin-right: 10px;
          margin-top: -10px;
          margin-left: auto;
          font-size: 8px;
          font-weight: 600;
          color: #000;
        }
      }
    }
  }

  .card-line-def-58-30 {
    width: 58mm;
    height: 30mm;
    padding: 4px;
    position: relative;

    .size-title {
      bottom: 0;
      position: absolute;
      margin: 0;
      font-size: 10px;
      color: #8200bf;
      font-weight: bold;
    }

    .card-body {
      width: 100%;
      .body-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          color: #000;
          margin: 6px 0 0 0;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 1px;
          width: 100%;
          text-align: center;
          // text-overflow: ellipsis;
          // overflow: hidden;
          // white-space: nowrap;
        }

        .cost-box {
          display: flex;
          align-items: center;
          gap: 5px;

          .cost {
            margin-top: 3px;
            font-size: 31px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
            color: #000;
          }
          .cost-changed {
            margin-top: 15px;
            font-size: 32px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .bottom {
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 1px;
            margin-top: 2px;
          }
          .bottom-changed {
            margin-top: 16px;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 1px;
          }
        }
      }
    }
  }

  .card-70-40 {
    width: 70mm;
    height: 40mm;
    padding: 4px;

    .card-body {
      display: flex;
      gap: 2px;
      position: relative;
      .logo {
        width: 30%;
        margin: auto 0;
        img {
          width: 100%;
          height: 70px;
        }
      }

      .body-items {
        width: 70%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .filial {
          margin: 4px 0 0 0;
          font-size: 9px;
          font-weight: 600;
          letter-spacing: 0.5px;
          color: #000;
        }

        .title {
          margin: 4px 0 0 0;
          font-size: 11px;
          font-weight: 600;
          color: #000;
          width: 100%;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .changed-title {
          margin: 5px 0 0 0;
          font-size: 15px;
          font-weight: 600;
          color: #000;
          width: 100%;
          text-align: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        .cost-box {
          display: flex;
          align-items: center;

          .cost {
            margin-top: 3px;
            font-size: 24px;
            font-weight: 900;
            letter-spacing: 1px;
            color: #000;
          }
          .cost-changed {
            margin-top: 15px;
            font-size: 25px;
            font-weight: 900;
            letter-spacing: 1px;
            color: #000;
            font-family: Arial, Helvetica, sans-serif;
          }
          .cost-divider {
            display: flex;
            flex-direction: column;
            align-items: center;
            .top {
              font-size: 14px;
              border-bottom: 1px solid black;
              font-weight: 600;
              letter-spacing: 1px;
              color: #000;
            }

            .bottom {
              font-size: 14px;
              font-weight: 600;
              letter-spacing: 1px;
              color: #000;
            }
          }
          .cost-divider-changed {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 15px;
            .top {
              font-size: 15px;
              border-bottom: 1px solid black;
              font-weight: bold;
              letter-spacing: 1px;
              color: #000;
            }
            .bottom {
              font-size: 15px;
              font-weight: bold;
              letter-spacing: 1px;
              color: #000;
            }
          }
        }

        .time {
          margin-left: auto;
          margin-right: 2px;
          font-size: 8px;
          color: #000;
          margin-right: 10px;
        }
      }

      .scale-code {
        font-size: 12px;
        color: #000;
        font-weight: 700;
        position: absolute;
        bottom: 10%;
        left: 35%;
      }
    }
  }

  .card-line-def-70-40 {
    width: 70mm;
    height: 40mm;
    padding: 4px;
    position: relative;

    .size-title {
      bottom: 0;
      position: absolute;
      margin: 0;
      font-size: 10px;
      color: #8200bf;
      font-weight: bold;
    }

    .card-body {
      width: 100%;
      .body-items {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
          color: #000;
          margin: 18px 0 0 0;
          font-size: 18px;
          font-weight: 500;
          letter-spacing: 1px;
          width: 100%;
          text-align: center;
          // text-overflow: ellipsis;
          // overflow: hidden;
          // white-space: nowrap;
        }

        .cost-box {
          display: flex;
          align-items: center;
          gap: 5px;

          .cost {
            margin-top: 3px;
            font-size: 32px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
            color: #000;
          }
          .cost-changed {
            margin-top: 15px;
            font-size: 32px;
            font-weight: 900;
            letter-spacing: 1px;
            font-family: Arial, Helvetica, sans-serif;
          }
          .bottom {
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 1px;
            margin-top: 2px;
          }
          .bottom-changed {
            margin-top: 16px;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 1px;
          }
        }
      }
    }
  }
}
