.dark-footer-expen {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 10px;
  .box {
    border: 1px solid #373e53;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: #141824;
    padding: 10px;
    color: #9fa6bc;
  }

  .main-check {
    display: flex;
    align-items: center;
  }
}

.footer-expen {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 10px;
  .box {
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: #fff;
    padding: 10px;
  }

  .main-check {
    display: flex;
    align-items: center;
  }
}

.exp-rows {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .exp-btn {
    display: flex;
    gap: 10px;
  }
}

.main-btn-group {
  display: flex;
  gap: 10px;
}

@media (max-width: 768px) {
  .footer-expen {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 8px;
    .box {
      width: 100%;
      align-items: center;
      background-color: transparent;
      padding: 8px;
    }
    .main-check {
      width: 100%;
      display: flex;
      align-items: center;
    }
    .main-btn-group {
      // margin-top: 10px;
      width: 100%;
      display: flex;
      gap: 10px;
    }
  }

  .exp-rows {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
}

.modal-classs {
  .modal-content {
    border: 2px solid #cbd0dd;
  }
}
