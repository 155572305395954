.loading-table {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: auto;
}

.loading-table::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ccc;
  opacity: 0.3;
  z-index: 1;
  pointer-events: none;
}

.loading-table span {
  color: black;
  z-index: 2;
  pointer-events: none;
}
