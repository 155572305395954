.form-cls {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.custom-btn {
  &:hover {
    padding: 10px;
    border-radius: 100px;
    background: #fff !important;
  }
}
@media (max-width: 768px) {
  .header-title {
    margin-bottom: 10px;
  }
  .form-cls {
    margin-top: 20px;
  }
  .create-row {
    display: flex;
    flex-direction: column;
  }

  .btn {
    width: 100%;
  }
}
