.languageContainer {
  display: flex;
  gap: 20px;
  padding: 10px 0;
}

.languageItem {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.radioInput {
  transform: scale(1); /* Make the radio button slightly larger */
}

.languageIcon {
  width: 24px;
  height: 24px;
}

.languageLabel {
  font-weight: 600;
  font-size: 0.8rem;
}
