.dark-footer-ret-coming {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 10px;
  .box {
    border: 1px solid #373e53;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: #141824;
    padding: 10px;
    color: #9fa6bc;
  }
}

.footer-ret-coming {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 10px;
  .box {
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: #fff;
    padding: 10px;
  }
}

.new-rows {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .new-btn {
    display: flex;
    gap: 10px;
  }
}

.main-btn-group {
  display: flex;
  gap: 10px;
}

@media (max-width: 768px) {
  .footer-ret-coming {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 8px;
    .box {
      width: 100%;
      align-items: center;
      background-color: #fff;
      padding: 8px;
    }
    .main-btn-group {
      width: 100%;
      display: flex;
      gap: 10px;
    }
  }

  .new-rows {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .new-btn {
      margin-top: 10px;
    }
  }
}
