.title {
  font-size: 14px;
  margin-bottom: 0px;
}
.text {
  font-size: 14px;
  margin-bottom: 0px;
  font-weight: 700;
}

.revProdRows {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .remBtn {
    margin-top: auto;
  }
}

.revInvRows {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  .revInvBtn {
    margin-top: auto;
  }
}

.revProdFooter {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 8px;
  .box {
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: #fff;
    padding: 10px;
  }
}

.darkRevProdFooter {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 8px;
  .box {
    border: 1px solid #373e53;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: #141824;
    padding: 10px;
    color: #9fa6bc;
  }
}

@media (max-width: 768px) {
  .revProdFooter {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 8px;
    .box {
      width: 100%;
      align-items: center;
      background-color: #fff;
      padding: 8px;
    }
  }

  .revInvRows {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .revInvBtn {
      margin-top: auto;
    }
  }

  .revProdRows {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .remBtn {
      margin-top: auto;
    }
  }
}
