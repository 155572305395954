#root {
  color: black;
}

.submenu {
  display: none;
  list-style: none;
}

.main-cat {
  margin-left: 0;
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style: none;
  cursor: pointer;
  li {
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
  }
}
