// Variables
$border-color: #cbd0dd;
$focused-border-color: #8200bf;
$bg-color: #fff;
$hover-bg-color: #f0f0f0;
$active-bg-color: #ddd;

.custom-dropdown {
  position: relative;
  width: 100%;

  .dropdown-header {
    padding: 10px;
    border: 1px solid $border-color;
    border-radius: 6px;
    cursor: pointer;
    transition: border-color 0.3s ease;

    &.focused {
      border-color: $focused-border-color;
      border: 1px solid #8200bf !important;
      outline: 0;
      box-shadow:
        inset 0 1px 2px transparent,
        0 0 0 0.25rem rgba(56, 116, 255, 0.25);
      transition: all 0.25s ease-in-out;
    }
  }

  .dropdown-content {
    overflow-y: auto;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    z-index: 10;
    background: #fff;
    border: 1px solid $border-color;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 300px;

    .dropdown-search {
      width: 100%;
      padding: 8px;
      border: none;
      border-bottom: 1px solid $border-color;
      outline: none;
      box-sizing: border-box;

      &:focus {
        border-bottom-color: #8200bf;
      }
    }

    .dropdown-options {
      list-style: none;
      margin: 0;
      padding: 0;
      max-height: 150px;
      overflow-y: auto;

      .option {
        padding: 10px;
        cursor: pointer;
        transition: background-color 0.2s;

        &:hover {
          background-color: #f1f1f1;
        }
      }
    }

    .no-options {
      padding: 10px;
      color: #888;
      text-align: center;
    }
  }
}

#root {
  color: black;
}

.main-container {
  display: flex;
  flex-direction: column;
  .fixed-input-container {
    border-radius: 8px;
    position: sticky;
    top: 0;
    z-index: 10; /* Ensure it stays above other elements */
    background-color: white; /* Match the background to avoid overlap issues */
    padding: 6px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */

    .search-input[type='text'] {
      border: none;
      border: 1px solid #8200bf;
      border-radius: 4px;
    }
    .search-input {
      padding: 5px;
      width: 100%;
      outline: none;
    }
  }

  .main-cat {
    border-radius: 8px;
    padding: 12px 24px;
    background-color: white; /* Match the background to avoid overlap issues */
    margin-left: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    cursor: pointer;
    li {
      font-weight: 600;
      font-size: 16px;
      line-height: 25px;
      .copy-icon {
        cursor: pointer;
        margin-left: 10px;
        transition: transform 0.1s ease-in-out;

        &:hover {
          opacity: 0.7;
        }

        &:active {
          transform: scale(0.9);
        }
      }
    }
  }
}
.main-container-dark {
  display: flex;
  flex-direction: column;
  .fixed-input-container {
    border-radius: 8px;
    position: sticky;
    top: 0;
    z-index: 10; /* Ensure it stays above other elements */
    background-color: #141824; /* Match the background to avoid overlap issues */
    padding: 6px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add shadow for better visibility */
    border: 1px solid #373e53;

    .search-input[type='text'] {
      border: none;
      border: 1px solid #8200bf;
      border-radius: 4px;
    }
    .search-input {
      padding: 5px;
      width: 100%;
      outline: none;
    }
  }

  .main-cat {
    border-radius: 8px;
    padding: 12px 24px;
    background-color: #141824; /* Match the background to avoid overlap issues */
    border: 1px solid #373e53;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
    list-style: none;
    cursor: pointer;
    li {
      font-weight: 600;
      font-size: 16px;
      line-height: 25px;
      color: #9fa6bc;
      .copy-icon {
        cursor: pointer;
        margin-left: 10px;
        transition: transform 0.1s ease-in-out;

        &:hover {
          opacity: 0.7;
        }

        &:active {
          transform: scale(0.9);
        }
      }
    }
  }
}
.submenu {
  display: none;
  list-style: none;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.spinner {
  border: 4px solid #f3f3f3; /* Light gray */
  border-top: 4px solid #8200bf; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
