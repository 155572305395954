.print-container {
  width: 80mm;
  margin: 0;
  padding: 0 15px 0 0;

  p {
    color: #000;
    font-weight: 700;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  .cash-check {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;

    .check-list {
      width: 60%;
      padding: 0;
      list-style: none;
      margin: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      li {
        color: #000;
        font-size: 12px;
        margin: 0;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
      }
    }

    .check-cost {
      width: 40%;
      padding: 0;
      list-style: none;
      margin: 0 4px 0 0;
      text-align: end;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      li {
        color: #000;
        font-size: 12px;
        font-weight: bold;
        margin: 0;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
