.dark-footer-return-info {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  gap: 20px;
  padding: 10px;
  .box {
    border: 1px solid #373e53;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: #141824;
    padding: 10px;
    color: #9fa6bc;
  }
}
.footer-return-info {
  display: flex;
  justify-content: flex-end;
  text-align: center;
  gap: 20px;
  padding: 10px;
  .box {
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: #fff;
    padding: 10px;
  }
}

.return-rows {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;
  .return-btn {
    display: flex;
    gap: 10px;
  }
}

.main-btn-group {
  display: flex;
  gap: 10px;
}

@media (max-width: 768px) {
  .footer-return-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 8px;
    .box {
      width: 100%;
      align-items: center;
      background-color: #d1d1d2;
      padding: 10px;
    }
    .main-btn-group {
      // margin-top: 10px;
      width: 100%;
      display: flex;
      gap: 10px;
    }
  }

  .return-rows {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .return-btn {
      margin-top: 10px;
    }
  }
}
